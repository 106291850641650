




















import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";
import { cloneDeep } from "lodash";

import SegmentForm from "@/segments/components/SegmentForm.vue";
import SegmentModel, { SegmentType } from "@/segments/models/SegmentModel";
import { GetSegmentRequestModel } from "@/segments/models/SegmentRequestModel";
import { MenuItems } from "@/shared/models/Menu";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import {
  FilterModel,
  FILTER_ID_TO_DATA_TYPES,
} from "@/shared/models/FilterModel";

@Component({
  components: {
    SegmentForm,
  },
})
export default class SegmentView extends mixins(UnsavedChangesMixin) {
  readonly filterIdToDataTypes = FILTER_ID_TO_DATA_TYPES;
  localSegment: SegmentModel | null = null;
  watchedSegment = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localSegment);
  }

  get segmentId(): number {
    return Number.parseInt(this.$route.params.segmentId);
  }

  get isLoading(): boolean {
    return (
      this.$store.state.segmentStore.loadingSegment ||
      this.externalTestLoading ||
      this.abTestLoading
    );
  }

  get externalTestLoading(): boolean {
    return this.$store.state.externalTestStore.isTestLoading;
  }

  get abTestLoading(): boolean {
    return this.$store.state.abTestConfig.loadingConfig;
  }

  get segment(): SegmentModel {
    return this.$store.state.segmentStore.segment;
  }

  @Watch("segment", { deep: true })
  private watchSegment(segment: SegmentModel) {
    this.localSegment = SegmentModel.of(segment);

    if (!this.watchedSegment) {
      this.watchedSegment = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  async created() {
    await this.$store.dispatch(
      "getSegment",
      new GetSegmentRequestModel(this.segmentId, this.applicationId)
    );

    document.title = this.$lang(
      "documentTitle",
      this.$lang("segment.viewTitle", this.segment.name || "")
    );

    if (this.segment.type === SegmentType.EXTERNAL_AB_TEST) {
      await this.$store.dispatch("getExternalTestBySegmentId", this.segmentId);
    } else if (this.segment.type === SegmentType.AB_TEST) {
      await this.$store.dispatch("getAbTestConfigBySegmentId", this.segmentId);
    }

    await this.$store.dispatch("loadDictionaries", {
      app: this.applicationId,
      dictionaryTypes: this.segment.filter.flatMap(
        ({ id }: FilterModel) => this.filterIdToDataTypes[id]
      ),
    });
    this.loadSegmentUsedDictionaries();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async save() {
    await this.$store.dispatch("updateSegment", this.localSegment);
    this.isSavedForm = true;
    this.$router.push({
      name: this.$route.params.route || MenuItems.SEGMENT,
      params: { id: this.applicationId },
    });
  }

  loadSegmentUsedDictionaries() {
    this.$store.dispatch("loadUsedDictionaries", {
      app: this.applicationId,
      values: this.segment.getUsedDictionaryValues(),
    });
    this.$store.dispatch("loadUsedTrackerDictionaries", {
      app: this.applicationId,
      values: this.segment.getUsedTrackerDictionaryValues(),
    });
  }
}
